import type { Category } from "~/domain/entity";
import { newApiErr, useZap } from "~/composables/logger/zap";
import { useCategoryApi } from "~/composables/api/category";
import { isInvalidResponse } from "~/utils/http/response";

export const useCategoryListStore = defineStore("categoryList", () => {
  const l = useZap("categoryListStore");
  const categoryApi = useCategoryApi();

  const is = reactive({
    fetching: false,
    fetched: false,
  });

  const categoryList = ref<Category[]>([]);
  const totalCategoryCount = ref(0);

  async function fetchCategoryList() {
    if (is.fetching) return;

    if (is.fetched) return;

    is.fetching = true;

    const _l = l.named("fetchCategoryList");

    try {
      // TODO пока что только 1 и 100
      const res = await categoryApi.getCategoryList({
        pagination: {
          page: 1,
          limit: 100,
        },
      });

      if (isInvalidResponse(res)) {
        throw newApiErr("isInvalidResponse", res);
      }

      if (!Array.isArray(res.data.payload.categoryList)) {
        throw newApiErr("invalidCategoryList", res);
      }

      categoryList.value = [
        ...categoryList.value,
        ...(res.data.payload.categoryList as Category[]),
      ];

      totalCategoryCount.value = res.data.payload.totalCategoryCount;

      is.fetched = true;
    } catch (e) {
      await _l.error(e);
    } finally {
      is.fetching = false;
    }
  }

  const publicGetters = {
    getIsFetching: computed(() => is.fetching),

    getCategoryList: computed(() => categoryList.value),
  };

  const publicActions = {
    fetchCategoryList: async function () {
      await fetchCategoryList();
    },
  };

  return {
    ...publicGetters,
    ...publicActions,

    // ssr
    is,
    categoryList,
  };
});
