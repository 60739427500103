<script setup lang="ts">
import type { Category } from "~/domain/entity";
import { PageName } from "~/utils/enum/page_name";

const props = defineProps<{
  category: Category;
}>();

const route = useRoute();

const getTo = computed(() => {
  if (route.name === PageName.CategoryList) {
    return {
      name: PageName.CategorySlug,
      params: {
        categorySlug: props.category.seoUrl
          ? props.category.seoUrl
          : props.category.id,
      },
      // query: {
      //   from: PageName.CategoryList,
      // },
    };
  }

  return {
    name: PageName.CategorySlug,
    params: {
      categorySlug: props.category.seoUrl
        ? props.category.seoUrl
        : props.category.id,
    },
  };
});
</script>

<template>
  <NuxtLink :to="getTo" :class="['w-fit g-link']" external>
    <div>
      <h3 class="pt-2 leading-6 relative font-medium w-fit text-lg break-words">
        {{ props.category.title }}
        <!--        <span>{{ props.category.totalProducts }}</span>-->
      </h3>
    </div>
  </NuxtLink>
</template>

<style scoped></style>
