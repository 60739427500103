<script setup lang="ts">
import type { Category } from "~/domain/entity";

const props = defineProps<{
  categoryList: Category[];
}>();
</script>

<template>
  <div>
    <div class="grid grid-cols-1 gap-4">
      <CategoryTocItem
        v-for="(category, idx) in props.categoryList"
        :key="idx"
        :category="category"
      />
    </div>
  </div>
</template>

<style scoped></style>
